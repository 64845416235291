.contact-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-text10 {
  display: inline-block;
  font-family: "Martian Mono";
  text-decoration: none;
}
.contact-text11 {
  display: inline-block;
  font-family: "Martian Mono";
}
.contact-text12 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.contact-text13 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.contact-text14 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.contact-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.contact-text16 {
  display: inline-block;
  font-family: "Martian Mono";
}
.contact-text17 {
  display: inline-block;
  font-family: "Martian Mono";
}
.contact-text18 {
  display: inline-block;
}
.contact-text19 {
  display: inline-block;
}
.contact-text20 {
  display: inline-block;
}
.contact-text21 {
  display: inline-block;
}
.contact-text22 {
  display: inline-block;
  font-family: "Martian Mono";
}
.contact-text23 {
  display: inline-block;
  font-family: "Martian Mono";
}
.contact-text24 {
  display: inline-block;
  font-family: "Martian Mono";
}
.contact-text25 {
  display: inline-block;
  font-family: "Martian Mono";
}
.contact-text26 {
  display: inline-block;
  font-family: "Martian Mono";
}
