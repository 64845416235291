.content-list1-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.content-list1-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.content-list1-content {
  align-self: stretch;
  align-items: flex-start;
}
.content-list1-ul {
  align-items: flex-start;
}
.content-list1-li12 {
  font-family: "Martian Mono";
}
.content-list1-li13 {
  font-family: "Martian Mono";
}
.content-list1-li14 {
  font-family: "Martian Mono";
}
.content-list1-li15 {
  font-family: "Martian Mono";
}
.content-list1-li16 {
  font-family: "Martian Mono";
}
.content-list1-li17 {
  font-family: "Martian Mono";
}
.content-list1-li18 {
  font-family: "Martian Mono";
}
.content-list1-li19 {
  font-family: "Martian Mono";
}
.content-list1-li20 {
  font-family: "Martian Mono";
}
.content-list1-li21 {
  font-family: "Martian Mono";
}
.content-list1-li22 {
  font-family: "Martian Mono";
}
.content-list1-text10 {
  font-family: "Martian Mono";
}
.content-list1-text11 {
  font-family: "Martian Mono";
}
.content-list1-text18 {
  font-family: "Martian Mono";
}
.content-list1-text40 {
  font-family: "Martian Mono";
}
.content-list1-text41 {
  font-family: "Martian Mono";
}
.content-list1-text42 {
  font-family: "Martian Mono";
}
.content-list1-text43 {
  font-family: "Martian Mono";
}
.content-list1-text44 {
  font-family: "Martian Mono";
}
.content-list1-text47 {
  font-family: "Martian Mono";
}
.content-list1-text57 {
  font-family: "Martian Mono";
}
.content-list1-text58 {
  font-family: "Martian Mono";
}
.content-list1-text61 {
  font-family: "Martian Mono";
}
.content-list1-text62 {
  font-family: "Martian Mono";
}
.content-list1-text74 {
  font-family: "Martian Mono";
}
.content-list1-text75 {
  font-family: "Martian Mono";
}
.content-list1-text78 {
  font-family: "Martian Mono";
}
.content-list1-text79 {
  font-family: "Martian Mono";
}
.content-list1-text80 {
  font-family: "Martian Mono";
}
.content-list1-text81 {
  font-family: "Martian Mono";
}
.content-list1-text82 {
  font-family: "Martian Mono";
}
.content-list1-text85 {
  font-family: "Martian Mono";
}
.content-list1-text88 {
  font-family: "Martian Mono";
}
.content-list1-text89 {
  align-self: flex-start;
  font-family: "Martian Mono";
}
.content-list1-text90 {
  font-family: "Martian Mono";
}
.content-list1-text98 {
  font-family: "Martian Mono";
}
.content-list1-text99 {
  font-family: "Martian Mono";
}
