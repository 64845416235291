.contact11-container {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.contact11-max-width {
  align-self: center;
}
.contact11-section-title {
  width: 100%;
  margin-bottom: var(--dl-space-space-threeunits);
}
.contact11-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact11-content2 {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact11-content3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact11-content4 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact11-contact-info1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact11-text2 {
  align-self: flex-start;
  text-align: center;
  font-family: "Martian Mono";
}
.contact11-email1 {
  text-decoration: none;
}
.contact11-content5 {
  margin-top: 16px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact11-content6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact11-content7 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact11-icon3 {
  width: 48px;
  height: 48px;
}
.contact11-contact-info2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact11-text3 {
  align-self: flex-start;
  text-align: center;
  font-family: "Martian Mono";
}
.contact11-email2 {
  text-decoration: none;
}
.contact11-text4 {
  font-family: "Martian Mono";
}
.contact11-text5 {
  font-family: "Martian Mono";
}
.contact11-text6 {
  font-family: "Martian Mono";
}
@media(max-width: 991px) {
  .contact11-content2 {
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .contact11-content5 {
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
}
