.legal-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.legal-text10 {
  display: inline-block;
  font-family: "Martian Mono";
  text-decoration: none;
}
.legal-text11 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text12 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.legal-text13 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.legal-text14 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.legal-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.legal-text16 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text17 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text18 {
  display: inline-block;
}
.legal-text19 {
  display: inline-block;
}
.legal-text20 {
  display: inline-block;
}
.legal-text21 {
  display: inline-block;
}
.legal-text22 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text27 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text47 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text57 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text60 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text63 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text71 {
  display: inline-block;
  align-self: flex-start;
  font-family: "Martian Mono";
}
.legal-text72 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text73 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text74 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text75 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text76 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text77 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text80 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text81 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text84 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text85 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text88 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text89 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text95 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text96 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text97 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text98 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text99 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text100 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text101 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text102 {
  display: inline-block;
  font-family: "Martian Mono";
}
.legal-text103 {
  display: inline-block;
  font-family: "Martian Mono";
}
