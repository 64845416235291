.hero17-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.hero17-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.hero17-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.hero17-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}
.hero17-button1 {
  text-decoration: none;
}
.hero17-button2 {
  text-decoration: none;
}
.hero17-container1 {
  width: 385px;
  height: 146px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.hero17-container2 {
  top: NaNpx;
  flex: 1;
  left: 0px;
  right: 0px;
  width: 285px;
  height: 100%;
  margin: auto;
  position: absolute;
  align-self: center;
}
.hero17-container3 {
  display: contents;
}
.hero17-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.hero17-row-container1 {
  width: 100%;
}
.hero17-placeholder-image10 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image11 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image12 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image13 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image14 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image15 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image16 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image17 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image18 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image19 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image20 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image21 {
  width: 300px;
  height: 300px;
}
.hero17-row-container2 {
  width: 100%;
}
.hero17-placeholder-image22 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image23 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image24 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image25 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image26 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image27 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image28 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image29 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image30 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image31 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image32 {
  width: 300px;
  height: 300px;
}
.hero17-placeholder-image33 {
  width: 300px;
  height: 300px;
}
.hero17-container5 {
  display: contents;
}
.hero17-text5 {
  text-align: center;
  font-family: "Martian Mono";
}
.hero17-text6 {
  font-family: "Martian Mono";
}
.hero17-text7 {
  font-family: "Martian Mono";
}
.hero17-text8 {
  text-align: center;
  font-family: "Martian Mono";
}
@media(max-width: 767px) {
  .hero17-content2 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .hero17-actions {
    width: 100%;
    flex-direction: column;
  }
  .hero17-button1 {
    width: 100%;
  }
  .hero17-button2 {
    width: 100%;
  }
  .hero17-container1 {
    height: auto;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .hero17-container2 {
    position: static;
    align-self: center;
  }
  .hero17-placeholder-image10 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image11 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image12 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image13 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image14 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image15 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image16 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image17 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image18 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image19 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image20 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image21 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image22 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image23 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image24 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image25 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image26 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image27 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image28 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image29 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image30 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image31 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image32 {
    width: 200px;
    height: 200px;
  }
  .hero17-placeholder-image33 {
    width: 200px;
    height: 200px;
  }
}
