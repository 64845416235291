.home-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-text10 {
  display: inline-block;
  font-family: "Martian Mono";
  text-decoration: none;
}
.home-text11 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text12 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text13 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text14 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text16 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text17 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text18 {
  display: inline-block;
}
.home-text19 {
  display: inline-block;
}
.home-text20 {
  display: inline-block;
}
.home-text21 {
  display: inline-block;
}
.home-text22 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text23 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text24 {
  display: inline-block;
  text-align: center;
  font-family: "Martian Mono";
}
.home-text25 {
  display: inline-block;
  text-align: center;
  font-family: "Martian Mono";
}
.home-text26 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text27 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text28 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text29 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text30 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text31 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text32 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text33 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text34 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text35 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text36 {
  display: inline-block;
  text-align: center;
  font-family: "Martian Mono";
}
.home-text37 {
  display: inline-block;
  text-align: center;
  font-family: "Martian Mono";
}
.home-text38 {
  display: inline-block;
  text-align: center;
  font-family: "Martian Mono";
}
.home-text39 {
  display: inline-block;
  text-align: center;
  font-family: "Martian Mono";
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text40 {
  font-size: 35px;
  margin-bottom: 32px;
}
.home-container3 {
  width: 100%;
  height: 289px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container4 {
  width: 846px;
}
.home-container5 {
  display: contents;
}
.home-text41 {
  display: inline-block;
  font-family: "Martian Mono";
}
.home-text42 {
  display: inline-block;
  font-family: "Martian Mono";
}
